<template>
	<section class="news blog_part min-height-80vh">
        <div class="container">
            <div class="breadcrump">
                <h4 class="py-3 mb-4">
                    <span class="text-muted fw-light">
                        <a href="/">Bosh sahifa</a> 
                        <i class="mdi mdi-chevron-right"></i> 
                        <a href="/news/index">Yangiliklar</a> 
                        <i class="mdi mdi-chevron-right"></i>
                    </span> 
                    <span class="breadcrump-icon"><i class="ti-calendar ml-1 mr-1"></i> {{ parseDate(model.createdDate) }}</span>
                </h4>
            </div>
            <div class="row">
                <div class="single-post" v-if="model != null">
                  <div class="blog_details mb-4">
                    <ul class="blog-info-link mb-4">
                        <h2>{{ model.titleUz }}</h2>
                        <li><a href="#"><i class="ti-calendar"></i>{{ parseDate(model.createdDate) }}</a></li>
                        <li><a href="#"><i class="ti-eye"></i>{{ model.readCount }}</a></li>
                    </ul>
                    <span v-html="model.bodyUz"></span>
                  </div>
               </div>
            </div>
        </div>
    </section>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
    components: {
		Paginate,
	},
    data() {
		return {
            id: 0,
            model: {},
        };
	},
	methods: {
        getData() {
			this.$api.get("/query_service/api/v1/news/get_news?id=" + this.id + "&limit=10")
            .then(response => {
				this.model = response.result.data[0];
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
        parseDate(date) {
			var parse = '0';
			if(date && date != 0){
				parse = moment(date).format('DD.MM.YYYY');
			}
			return parse;
		},
        contentText(){

        }
	},
	mounted() {
        this.id = parseInt(this.$route.params.id);
        this.getData();
	},
    created() {
		if (!this.$route.params.id) {
			this.$router.push({ path: "/" });
			return;
		}
	},
}
</script>